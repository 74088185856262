import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const timesheetUrl = `${config.api}/v1/user/timesheet`

let state

const toActivityMenu = (list = []) => {
  const menuList = list.map((it) => {
    const { ts_activity_id, activity_name } = it
    return {
      name: activity_name,
      value: ts_activity_id,
    }
  })

  return menuList
}

const toCategoryMenu = (list = []) => {
  const menuList = list.map((it) => {
    const { ts_category_id, category_name } = it
    const activity_list = toActivityMenu(it.activity_list || [])
    return {
      name: category_name,
      value: ts_category_id,
      tag: activity_list,
    }
  })

  return menuList
}

const toCostCenterMenu = (list = []) => {
  const menuList = list.map((it) => {
    const { ts_cost_center_id, cost_center_name } = it
    const category_list = toCategoryMenu(it.category_list || [])
    return {
      name: cost_center_name,
      value: ts_cost_center_id,
      tag: category_list,
    }
  })

  return menuList
}

const toFilterMenu = (list = []) => {
  const menu = list.map((it) => {
    const { ts_category_id, cost_center_name, category_name } = it
    return {
      name: `${category_name} (${cost_center_name})`,
      value: ts_category_id,
    }
  })

  return menu
}

const getTotal = (totalList, list = []) => {
  const len = list.length
  let total = 0
  for (let i = 0; i < len; i++) {
    const item = list[i]
    const minute = item.minute || 0
    total += minute

    totalList[i].minute += minute
  }

  return total
}

const toCalc = (srcTotal, list = []) => {
  const totalList = srcTotal.date_list.map((it) => {
    it.minute = 0
    return it
  })

  let minute_total = 0
  for (const category of list) {
    const activity_list = category.activity_list || []
    for (const activity of activity_list) {
      const date_list = activity.date_list || []

      const val = getTotal(totalList, date_list)
      activity.minute_total = val

      minute_total += val
    }
  }

  const total = {
    minute_total,
    date_list: totalList,
  }
  return { total, list }
}

const toMonday = (date) => {
  const weekday = date.weekday()
  let counter = 0

  switch (weekday) {
    case 0: // Sunday
      counter = 6
      break
    case 2: // Tue
      counter = 1
      break
    case 3: // Wed
      counter = 2
      break
    case 4:
      counter = 3
      break
    case 5:
      counter = 4
      break
    case 6:
      counter = 5
      break
    default:
      counter = 0
  }

  return counter ? date.clone().subtract(counter, 'days') : date
}

export class Timesheet extends BaseStore {
  constructor() {
    super()
    this.observable({
      // all of project in app
      project_menu: [],
      summary_list: [],
      history: {
        category_menu: [],
        total: {
          minute: 0,
          date_list: [],
        },
        list: [],
      },
      setting: {
        view_part_at: timer.get().subtract(30, 'days'),
        edit_part_at: timer.get().subtract(30, 'days'),
        minimum_minute_dialy: 480,
        minimum_minute_weekly: 2400,
      },

      weekly_menu: [],
    })

    state = this
  }

  async getConfig(start) {
    const url = `${timesheetUrl}/config`
    const res = await http.get(url)
    const { setting, weekly_list = [] } = res.body || {}

    // selected default
    let selected = 0
    if (start) {
      const date = toMonday(timer.get(start, 'DDMMYY'))
      const startTxt = date.toISOString()

      const index = weekly_list.findIndex((it) => it.start_at === startTxt)
      if (index !== -1) {
        selected = index
      }
    }

    const menu = weekly_list.map((it) => {
      const { text } = it
      it.start_at = timer.get(it.start_at)
      it.finish_at = timer.get(it.finish_at)
      return {
        name: text,
        value: text,
        tag: it,
      }
    })

    runInAction(() => {
      state.weekly_menu = menu
      state.setting = setting
    })

    return { selected, menu }
  }

  async getProjectMenu() {
    const url = `${timesheetUrl}/cost-center`
    const res = await http.get(url)
    const list = res.body || []
    const menuList = toCostCenterMenu(list)

    runInAction(() => {
      state.project_menu = menuList
    })

    return menuList
  }

  async getTimesheet({ start_at, finish_at } = {}) {
    const sTxt = start_at.format('DDMMYY')
    const fTxt = finish_at.format('DDMMYY')
    const url = `${timesheetUrl}/report/detail/${sTxt}/${fTxt}`
    const res = await http.get(url)
    const { timesheet_list, total = {} } = res.body || {}

    const category_menu = toFilterMenu(timesheet_list)
    const date_list = total.date_list.map((it) => {
      it.work_at = timer.get(it.work_at)
      return it
    })
    runInAction(() => {
      state.history = {
        category_menu,
        total: {
          minute_total: total.minute_total,
          date_list,
        },
        list: timesheet_list,
      }
    })
  }

  async removeByCategory({ ts_category_id, start_at, finish_at }) {
    const sTxt = start_at.format('DDMMYY')
    const fTxt = finish_at.format('DDMMYY')
    const url = `${timesheetUrl}/request/${ts_category_id}/${sTxt}/${fTxt}`
    await http.delete(url)

    const { total, list } = this.toJS().history

    const index = list.findIndex((it) => {
      return it.ts_category_id === ts_category_id
    })

    list.splice(index, 1)
    const category_menu = toFilterMenu(list)
    const res = toCalc(total, list)
    runInAction(() => {
      state.history = {
        category_menu,
        total: res.total,
        list: res.list,
      }
    })
  }

  async removeByActivity({
    ts_category_id,
    ts_activity_id,
    start_at,
    finish_at,
  }) {
    const sTxt = start_at.format('DDMMYY')
    const fTxt = finish_at.format('DDMMYY')
    const url = `${timesheetUrl}/request/${ts_category_id}/${ts_activity_id}/${sTxt}/${fTxt}`
    await http.delete(url)

    const { total, list } = this.toJS().history

    const iCate = list.findIndex((it) => {
      return it.ts_category_id === ts_category_id
    })

    const category = list[iCate]
    const activity_list = category.activity_list

    const iActivity = activity_list.findIndex((it) => {
      return it.ts_activity_id === ts_activity_id
    })

    activity_list.splice(iActivity, 1)
    if (activity_list.length === 0) {
      list.splice(iCate, 1)
    } else {
      category.activity_list = activity_list
    }

    const category_menu = toFilterMenu(list)
    const res = toCalc(total, list)
    runInAction(() => {
      state.history = {
        category_menu,
        total: res.total,
        list: res.list,
      }
    })
  }

  async createTimesheetList({
    ts_category_id,
    ts_activity_id,
    start_at,
    finish_at,
  }) {
    const sTxt = start_at.format('DDMMYY')
    const fTxt = finish_at.format('DDMMYY')
    const url = `${timesheetUrl}/request/${ts_category_id}/${ts_activity_id}/${sTxt}/${fTxt}`
    const res = await http.post(url)
    const data = res.body || {}

    const history = this.toJS().history
    const list = history.list

    const { ts_cost_center_id } = data
    const index = list.findIndex((it) => {
      return (
        it.ts_cost_center_id === ts_cost_center_id &&
        it.ts_category_id === data.ts_category_id
      )
    })

    if (index === -1) {
      list.unshift({
        ts_cost_center_id: data.ts_cost_center_id,
        ts_category_id: data.ts_category_id,
        cost_center_name: data.cost_center_name,
        category_name: data.category_name,
        detail: data.category_detail,
        activity_list: [data],
      })
    } else {
      const category = list[index]
      const i = category.activity_list.findIndex(
        (it) => it.ts_activity_id === data.ts_activity_id
      )

      if (i === -1) {
        category.activity_list.push(data)
      }
    }

    const category_menu = toFilterMenu(list)

    runInAction(() => {
      state.history = {
        category_menu,
        total: history.total,
        list,
      }
    })
  }

  async createTimesheetByCategory({ ts_category_id, start_at, finish_at }) {
    const sTxt = start_at.format('DDMMYY')
    const fTxt = finish_at.format('DDMMYY')
    const url = `${timesheetUrl}/request/${ts_category_id}/${sTxt}/${fTxt}`
    const res = await http.post(url)
    const data = res.body || {}

    const history = this.toJS().history
    const list = history.list

    list.unshift(data)

    const category_menu = toFilterMenu(list)

    runInAction(() => {
      state.history = {
        category_menu,
        total: history.total,
        list,
      }
    })
  }

  async updateTimesheet(
    { timesheet_id, ts_category_id, ts_activity_id },
    { minute, note }
  ) {
    const json = { minute, note }
    const url = `${timesheetUrl}/request/${timesheet_id}`
    await http.put(url, { json })

    const { category_menu, total, list } = this.toJS().history

    const iCate = list.findIndex((it) => {
      return it.ts_category_id === ts_category_id
    })

    const category = list[iCate]
    const activity_list = category.activity_list

    const iActivity = activity_list.findIndex((it) => {
      return it.ts_activity_id === ts_activity_id
    })
    const activity = activity_list[iActivity]

    const date_list = activity.date_list || []
    const iDate = date_list.findIndex((it) => {
      return it.timesheet_id === timesheet_id
    })

    const timesheet = date_list[iDate]

    timesheet.minute = minute
    timesheet.note = note
    date_list[iDate] = timesheet

    category.activity_list = activity_list
    const res = toCalc(total, list)

    runInAction(() => {
      state.history = {
        category_menu,
        total: res.total,
        list: res.list,
      }
    })
  }

  async createTimesheet(
    { ts_category_id, ts_activity_id, work_at },
    { minute, note }
  ) {
    const json = {
      ts_category_id,
      ts_activity_id,
      work_at,
      minute,
      note,
    }
    const url = `${timesheetUrl}/request`
    const resp = await http.post(url, { json })
    const data = resp.body || {}
    const workAt = data.work_at

    const { category_menu, total, list } = this.toJS().history

    const iCate = list.findIndex((it) => {
      return it.ts_category_id === ts_category_id
    })

    const category = list[iCate]
    const activity_list = category.activity_list

    const iActivity = activity_list.findIndex((it) => {
      return it.ts_activity_id === ts_activity_id
    })
    const activity = activity_list[iActivity]

    const date_list = activity.date_list || []
    const iDate = date_list.findIndex((it) => {
      return it.work_at === workAt
    })

    if (iDate === -1) return

    const timesheet = date_list[iDate]

    timesheet.minute = minute
    timesheet.note = note
    date_list[iDate] = timesheet

    category.activity_list = activity_list
    const res = toCalc(total, list)

    runInAction(() => {
      state.history = {
        category_menu,
        total: res.total,
        list: res.list,
      }
    })
  }

  async getSummaryList({ month } = {}) {
    let url = `${timesheetUrl}/report/history`
    if (month) {
      const txt = month.format('DDMMYY')
      url += `?month=${txt}`
    }
    const res = await http.get(url)
    const list = res.body || []
    const result = list.map((it) => {
      it.start_at = timer.get(it.start_at)
      it.finish_at = timer.get(it.finish_at)
      return it
    })

    runInAction(() => {
      state.summary_list = result
    })
  }
}

const timesheet = new Timesheet()
export default timesheet
/*
{
    "total": {
        "minute_total": 195,
        "date_list": [
            {
                "minute": 0,
                "work_at": "2024-10-20T17:00:00.000Z"
            },
            {
                "minute": 0,
                "work_at": "2024-10-21T17:00:00.000Z"
            },
            {
                "minute": 0,
                "work_at": "2024-10-22T17:00:00.000Z"
            },
            {
                "minute": 195,
                "work_at": "2024-10-23T17:00:00.000Z"
            },
            {
                "minute": 0,
                "work_at": "2024-10-24T17:00:00.000Z"
            },
            {
                "minute": 0,
                "work_at": "2024-10-25T17:00:00.000Z"
            },
            {
                "minute": 0,
                "work_at": "2024-10-26T17:00:00.000Z"
            }
        ]
    },
    "timesheet_list": [
        {
            "ts_cost_center_id": "27fdc1b7-ce4b-4b2f-99ae-33f6273fff18",
            "ts_category_id": "29f7e5e9-e547-4134-bac5-4f8bb1a5c9ec",
            "cost_center_name": "Product",
            "category_name": "Survey",
            "activity_list": [
                {
                    "ts_activity_id": "de2dfe80-2759-4809-81ab-a9945379a322",
                    "activity_name": "Develop",
                    "minute_total": 60,
                    "date_list": [
                        {
                            "minute": 0,
                            "work_at": "2024-10-20T17:00:00.000Z",
                            "timesheet_id": "253d903c-dfd4-4c85-9c74-75246de21887",
                            "note": ""
                        },
                        {
                            "minute": 0,
                            "work_at": "2024-10-21T17:00:00.000Z",
                            "timesheet_id": "92730371-c5ca-4999-8adc-7593a42cf6f6",
                            "note": ""
                        },
                        {
                            "minute": 0,
                            "work_at": "2024-10-22T17:00:00.000Z",
                            "timesheet_id": "9e1ab0a5-2ee3-4d90-b6a5-bb1a1119cfec",
                            "note": ""
                        },
                        {
                            "minute": 60,
                            "work_at": "2024-10-23T17:00:00.000Z",
                            "timesheet_id": "008a4dac-b593-4efd-aa6c-64cc51f7b650",
                            "note": ""
                        },
                        {
                            "minute": 0,
                            "work_at": "2024-10-24T17:00:00.000Z",
                            "timesheet_id": "8e6b8451-dafb-4ca9-ab1a-fefbbbdef69e",
                            "note": ""
                        },
                        {
                            "minute": 0,
                            "work_at": "2024-10-25T17:00:00.000Z",
                            "timesheet_id": "ec30e92c-0e38-4eb3-959b-1834e2df2777",
                            "note": ""
                        },
                        {
                            "minute": 0,
                            "work_at": "2024-10-26T17:00:00.000Z",
                            "timesheet_id": "f14d3aed-7a10-44e9-92ea-4bc36c3c77fa",
                            "note": ""
                        }
                    ]
                }
            ]
        }
    ]
}
*/
