import CryptoJS from 'crypto-js'

import { http, error, firebase, timer } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'
import member from './Member'

const PublicUrl = `${config.api}/v1/public/account/employee`
const K = 'hlo-application'
export class Phone extends BaseStore {
  constructor() {
    super()
    this.observable({
      feature: {},
    })
  }

  async sendOtp(json = {}) {
    const url = `${PublicUrl}/phone-code`
    const { country_code, mobile } = json
    const date = timer.get().format('DDMMYYYYHHmmss')
    const data = JSON.stringify({ date, country_code, mobile })
    const token = CryptoJS.AES.encrypt(data, K).toString()

    await http.post(url, { json: { token } })
  }

  async verifyOtp(json = {}) {
    const url = `${PublicUrl}/login-otp`

    const res = await http.post(url, { json })
    const data = res.body || {}
    const { user, company, feature, token } = data

    const resToken = await firebase.signInWithToken(token)
    error.isError(resToken.error, 'เกิดข้อผิดพลาด account ของคุณ')

    const id_token = await resToken.user.getIdToken()
    http.setToken(id_token)

    member.setAllProfile(user, feature, company)
  }
}

export default new Phone()
