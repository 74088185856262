import styled from 'styled-components'

import LoadingIcon from './LoadingIcon'

const BaseBtn = (props) => {
  const {
    loading = false,
    disabled,
    size,
    icon,
    tag,
    color,
    bg_color,
    border_color,
    onClick,
  } = props

  const onClickItem = () => {
    onClick(tag)
  }

  let click
  let css
  if (!disabled) {
    click = onClickItem
    css = 'click'
  }

  const icon_content =
    loading && !disabled ? (
      <LoadingIcon loading={true} />
    ) : icon ? (
      icon
    ) : undefined

  return (
    <PageView
      onClick={click}
      className={css}
      size={size}
      color={color}
      bg_color={bg_color}
      border_color={border_color}
    >
      {icon_content}
    </PageView>
  )
}

const PageView = styled.span`
  border-radius: 20px;
  padding: 2px 3px;
  width: ${(props) => props.size || '35px'};
  height: ${(props) => props.size || '35px'};

  color: ${(props) => props.color};
  background-color: ${(props) => props.disabled};
  border: 1px solid ${(props) => props.border_color};

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  &.click {
    cursor: pointer;
    background-color: ${(props) => props.bg_color};
  }
`

export default BaseBtn
